import axios, { AxiosInstance, AxiosStatic, CancelStatic, CancelTokenStatic } from "axios"
import {apiURL, ssoMiddlewareHost} from "../app.config"
import store from "../store"

export interface ApiInstance extends AxiosInstance {
  Cancel: CancelStatic;
  CancelToken: CancelTokenStatic;
  isCancel: AxiosStatic["isCancel"];
}

const api: AxiosInstance = axios.create({
  baseURL: apiURL,
  headers: {
    common: {
      Accept: "application/json",
    },
    post: {
      "Content-Type": "application/json",
    },
  },
})

api.interceptors.request.use((config) => {
  const clinicId = store.getState()?.clinic?.clinicSettings?.id.replace("/api/clinics/", "")

  if (config?.url?.includes(ssoMiddlewareHost) && clinicId) {
    config.headers["x-tenant"] = clinicId
  }

  return config
})

api.interceptors.response.use()

//add ability to cancel axios requests
Object.defineProperty(api, "Cancel", {
  value: axios.Cancel
})
Object.defineProperty(api, "CancelToken", {
  value: axios.CancelToken
})
Object.defineProperty(api, "isCancel", {
  value: axios.isCancel
})

export default api as ApiInstance
