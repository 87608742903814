import styled, { css } from "styled-components"

export const StyledInput = styled.input<{hasError: boolean, id: string, type: string}>`
    width: 100%;
    height: 100%;
    border: 2px solid ${({ theme }) => theme.colors.telemediGray20};
    padding: 12px;
    height: 48px;
    border-radius: 8px;
    font-size: 16px;
    
    &:focus,
    &:active {
      border: 2px solid ${({ theme }) => theme.colors.telemediGreen};
    }

    ${({ hasError }) => hasError && css`
      border-color: ${({ theme }) => theme.colors.telemediRed};
    `}
    
    @media ${({ theme }) => theme.breakpoints.small} {
      ${({ id, hasError }) => (hasError && id !== "prefixCode") && css`
        padding-right: 60px;
      `}
    }

    ${({ type }) => type === "password" && css`
        padding-right: 35px;
    `}
    
    &,
    ::placeholder,
    ::-webkit-input-placeholder {
       color: ${({ theme }) => theme.colors.telemediBlack};
       font-size: 12px;
    };
    
    @media ${({ theme }) => theme.breakpoints.small} {
      &,
      ::placeholder,
      ::-webkit-input-placeholder {
        font-size: 16px;
      };
    }
`

export const StyledErrorMessage = styled.div`
    color:  ${({ theme }) => theme.colors.telemediRed};
    font-size: 14px;
    margin-top: 10px;
`

export const StyledWarningIcon = styled.span<{hidden: boolean}>`
  display: none;
  position: absolute;
  right: 10px;
  top: 11px;
  
  @media ${({ theme }) => theme.breakpoints.small} {
    display: block;
    display: ${({ hidden}) => hidden ? "none" : "block"}
  }
`
