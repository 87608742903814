import { Method } from "axios"
import { AxiosRequestConfig } from "axios"
import i18next from "i18next"
import { ssoMiddlewareHost } from "../app.config"

interface RouteConfig extends Partial<AxiosRequestConfig> {
  method: Method,
  url: string,
}

export const getCountriesListConfig: RouteConfig = {
  method: "GET",
  url: `/api/v2/tools/countries/${i18next.language}`,
}

export const registerUserConfig: RouteConfig = {
  method: "POST",
  url: "/api/v2/patients"
}

export const getEmailAddressExists = (email: string): RouteConfig => ({
  method: "GET",
  "url": `/api/v2/patients/find-by-text/${email}`
})

export const getPhoneCountryCodesConfig: RouteConfig = {
  method: "GET",
  url: `/api/v2/tools/country-codes/${i18next.language}`
}

export const logInConfig: RouteConfig = {
  method: "POST",
  withCredentials: true,
  url: `${ssoMiddlewareHost}/login-password-grant`
  // token: NOT required
}

const routes = {
  getCountriesListConfig,
  registerUserConfig,
  getPhoneCountryCodesConfig,
  logInConfig
}

export default routes
