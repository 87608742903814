import { configureStore } from "@reduxjs/toolkit"
import {
  getDefaultMiddleware,
} from "@reduxjs/toolkit"
import createRootReducer from "./rootReducer"
import * as Sentry from "@sentry/react"
// import {createBrowserHistory} from "history"

// export const history = createBrowserHistory()
const sentryEnhancer = Sentry.createReduxEnhancer({})

const store = configureStore({
  reducer: createRootReducer(),
  middleware: getDefaultMiddleware({
    serializableCheck: false
  }),
  enhancers: [sentryEnhancer],
})

export default store
