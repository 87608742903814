import React, { FC } from "react"
import RegisterForm from "../registerForm/RegisterForm.component"
import ClinicLogo from "../registerForm/ClinicLogo.component"
import SetLanguage from "../languageInfo/LanguageInfo.component"
import { useTranslation } from "react-i18next"

import {
  StyledRegisterContainer,
  StyledRegisterContent,
  StyledRegisterHeader,
  StyledTopHeader,
} from "./Register.styles"

interface RegisterProps {}

const Register: FC<RegisterProps> = () => {
  const { t } = useTranslation()

  return (
    <StyledRegisterContainer>
      <StyledRegisterContent>
        <RegisterForm>
          <ClinicLogo />
          <StyledTopHeader>
            <StyledRegisterHeader>
              { t("defaultTranslations:createAccountHeader") }
            </StyledRegisterHeader>
            <SetLanguage />
          </StyledTopHeader>
        </RegisterForm>
      </StyledRegisterContent>
    </StyledRegisterContainer>
  )
}

export default Register
