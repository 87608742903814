import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {CountriesListState} from "./countries.types"
import {Reducer} from "redux"

const initialState: CountriesListState = {
  countriesList: [],
  loading: false,
}

const countriesSlice = createSlice({
  name: "countries",
  initialState,
  reducers: {
    saveCountriesList: (state: CountriesListState, action: PayloadAction<CountriesListState>) => {
      state.countriesList = action?.payload?.data?.countries
      return
    },
  }
})

export const {saveCountriesList} = countriesSlice.actions

export default countriesSlice.reducer as Reducer<typeof initialState>
