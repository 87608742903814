import {UseFormRegister} from "react-hook-form"

type InputTypes = "password" | "checkbox" | "text" | "button" | "submit" | "email" | undefined

export interface RegisterFormTypes {
  name: string;
  register: UseFormRegister<any>;
  errors?: any;
  type?: InputTypes;
  validation?: any;
  placeholder?: string;
}

export type RegisterFormData = {
  email: string;
  plainPassword: string;
  plainPasswordRepeat: string;
  firstName: string;
  lastName: string;
  nationality: string;
  identityNumber: string;
  locale: string;
  birthDate: string;
  prefixCode: string;
  phoneNumber: string;
  termsAccepted?: boolean;
  collectMedicalDocument?: boolean;
  mailingAgree: boolean;
  confirmDuplicateEmail: boolean;
  identityDocumentCountryIssuer: string;
  identityDocumentType: string;
  pesel?: string;
  passportNumber?: string;
  passportCountry?: string;
  voucher?: string;
  defaultTimezone: string;
}

export enum DocumentType {
 IDENTITY_CARD_NUMBER = "1",
 PASSPORT = "4",
 PESEL = "5",
 NO_DOCUMENT = "6",
}

export enum MainCountriesTypes {
  PL = "PL",
  ES = "ES",
}

export type CountryCodesSelect = {
  value: string;
  label: string;
}

export type CustomErrorMessageType = {
  title: string;
  message: string;
}|null

export enum RegisterErrorsType {
  SIGNAL_IDUNA_INSURANCE_NOT_ACTIVE = 47,
  SIGNAL_IDUNA_INSURANCE_ERROR = 48
}

export type PhoneCountryCodes = Record<Code, CodeWithCountry> // { "+93": "Afganistan (+93)"}

export type errorFieldTypes = "voucher" | "birthDate"

type Code = string; //  "+93"
type CodeWithCountry = string; // "Afganistan (+93)"

