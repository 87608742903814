import React, {FC} from "react"
import {useSelector} from "react-redux"
import {selectClinicSettings} from "../../../store/clinicSettings/clinicSettings.selectors"
import {StyledHeader, StyledImage} from "./Header.styles"

const Header: FC = () => {
    const clinicSettings = useSelector(selectClinicSettings)
    const frontendSettings = clinicSettings?.frontendSettings

    return (
        <StyledHeader>
            <StyledImage src={frontendSettings?.images?.logo} alt={frontendSettings?.seo?.title} />
        </StyledHeader>
    )
}

export default Header
