import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {UserSettingsState} from "./userSettings.types"
import {Reducer} from "redux"

const initialState: UserSettingsState = {
  prefixCodeSet: false,
  userIsRegistered: false,
  username: "",
  password: ""
}

type userData = {
  username: string,
  password: string
}

const userSettingsSlice = createSlice({
  name: "userSettings",
  initialState,
  reducers: {
    setPrefixCode: (state: UserSettingsState) => {
      state.prefixCodeSet = true
    },
    setUserIsRegistered: (state: UserSettingsState) => {
      state.userIsRegistered = true
    },
    setUserData: (state: UserSettingsState, action: PayloadAction<userData>) => {
      state.username = action.payload.username
      state.password = action.payload.password
    },
  }
})

export const {setPrefixCode, setUserIsRegistered, setUserData} = userSettingsSlice.actions

export default userSettingsSlice.reducer as Reducer<typeof initialState>
