import {theme as defaultTheme} from "../../theme/theme"
import {ClinicSettingsState} from "./clinicSettings.types"
import { colors } from "../../theme/default/colors"
import store from "../index"
import {setClinicSettings} from "./clinicSettings.slice"

/* eslint-disable no-useless-escape */

export const getClinicId = () => {
  const params = new URLSearchParams(window.location.search)
  const paramsObject = Object?.fromEntries?.(params)

  return paramsObject.clinic
}

export const setClinicStyles = (clinicStyle: ClinicSettingsState["clinicSettings"]["frontendSettings"]["style"]) => {
  if( !clinicStyle ){
    return defaultTheme
  }
  //explicitly checking if it isn't null because 0 is falsey
  const shouldUseDynamicButtonBorderRadius = clinicStyle.buttonBorderRadius !== null && clinicStyle.dynamicStylesEnabled
  const buttonBorderRadius = shouldUseDynamicButtonBorderRadius ? clinicStyle.buttonBorderRadius : defaultTheme.buttons.borderRadius
  return {
    ...defaultTheme,
      colors: {
        ...colors,
        telemediGreen: clinicStyle.mainColor || colors.telemediGreen,
        telemediGreenDark: clinicStyle.hoverColor || colors.telemediGreenDark,
        telemediGreenDarker: clinicStyle.hoverColor || colors.telemediGreenDarker,
      },
      buttons:{
        ...defaultTheme.buttons,
        borderRadius: buttonBorderRadius
      }
    }
}

export const handleSetClinicSettings = (clinicSettings: ClinicSettingsState["clinicSettings"]) => {
  store.dispatch(setClinicSettings(clinicSettings))
  setClinicElements(clinicSettings)
}

const setClinicElements = (clinicSettings: ClinicSettingsState["clinicSettings"]) => {
  const {frontendSettings: {seo: {title}, images}} = clinicSettings

  if (images?.favicon) {
    document.getElementById("favicon")?.setAttribute("href", images?.favicon)
  }

  document.title = title || window.document.title
}
