import React, {FC} from "react"
import {UseFormRegister} from "react-hook-form"
import { useTranslation } from "react-i18next"
import Email from "../common/email/Email.component"
import Password from "../common/password/Password.component"

import {
  StyledRow,
} from "../RegisterForm.styles"

interface RegisterFormStep1Props {
  errors?: any;
  register: UseFormRegister<any>;
  setValue(name: string, value: string): void;
}

const RegisterFormStep1: FC<RegisterFormStep1Props> = ({register, errors, setValue}) => {
  const { t } = useTranslation()

  return (
      <>
        <StyledRow>
          <Email
            name="email"
            register={register}
            errors={errors}
          />
        </StyledRow>

        <StyledRow>
          <Password
            register={register}
            name="plainPassword"
            errors={errors}
            placeholder={t("defaultTranslations:registerForm:placeholder:password")}
            setValue={setValue}
          />
        </StyledRow>

        <StyledRow>
          <Password
            register={register}
            name="plainPasswordRepeat"
            errors={errors}
            placeholder={t("defaultTranslations:registerForm:placeholder:passwordRepeat")}
            setValue={setValue}
          />
        </StyledRow>
    </>
  )
}

export default RegisterFormStep1
