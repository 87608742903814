import React, { FC } from "react"
import { RegisterFormTypes } from "../../RegisterForm.types"
import { StyledSelect } from "./Select.styles"
import {StyledErrorMessage} from "../input/Input.styles"
import {ErrorMessage} from "@hookform/error-message"

interface SelectProps extends RegisterFormTypes {
  children: JSX.Element | JSX.Element[] | HTMLOptionElement | unknown;
  defaultValue: string;
  onChange?(event: React.ChangeEvent<HTMLSelectElement>): unknown;
  id?: string;
}

const Select: FC<SelectProps> = (
  {
    register,
    name,
    children,
    defaultValue,
    onChange,
    errors,
    id,
  }
) => {
  const hasError =  errors?.[name]

  return (
    <>
      <StyledSelect
        defaultValue={defaultValue}
        {...register(name, {})}
        onChange={onChange}
        hasError={hasError}
        id={id || name}
      >
        { children }
      </StyledSelect>
      <StyledErrorMessage>
        {
          (errors && errors.identityDocumentType) &&
            (
               <ErrorMessage errors={errors} name={name} />
            )
        }
      </StyledErrorMessage>
    </>
  )
}

export default Select
