
import { colors } from "./default/colors"
import { breakpoints } from "./default/breakpoints"
import { typography } from "./default/typography"
import { buttons } from "./default/buttons";
import {DefaultTheme} from "styled-components";

export const theme: DefaultTheme = {
  colors,
  breakpoints,
  typography,
  buttons
}
