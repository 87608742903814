import styled, {css} from "styled-components"

export const StyledRegisterForm = styled.div`
    background: ${({ theme }) => theme.colors.telemediWhite};
    margin: 20px;
    padding: 40px 20px 40px;
    border-radius: 28px;
    
    @media ${({ theme }) => theme.breakpoints.small} {
        padding: 60px 40px 40px;
        margin: 20px 0 0;
    }
`

export const StyledActionButtons = styled.div`
    margin-top: 40px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    &,
    button {
      width: 100%;
    }
    
    button:last-child {
      margin-bottom: 25px;
    }
    
    @media ${({ theme }) => theme.breakpoints.small} {
      &,
      button {
        width: auto;
      }
      
      button:last-child {
        margin-bottom: 0;
      }
      
      flex-direction: row;
      justify-content: flex-end;
    }
`

export const StyledRow = styled.div`
  margin-bottom: 25px;
  position: relative;
`

export const StyledRowSelectInput = styled.div`
  display: flex;
  justify-content: space-between;
  
  & select {
    width: 33%;
    margin-right: 8px;
    padding: 12px 0 12px 5px;
  }
  
  & input + div {
    display: none;
  }
  
  @media ${({ theme }) => theme.breakpoints.small} {
    & select {
      padding: 12px 20px 12px 12px;
    }
  }
`

export const StyledRegisterSuccessContent = styled.div`
  display: flex;
  margin-top: 24px;
`

export const StyledPhoneSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
  
  div {
    width: 100%;
    
    &:first-child {
      max-width: 93px;
      min-height: 21px;
    }
  }
`

export const StyledRegisterStepInfo = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 28px;
`

export const StyledImg = styled.img`
  cursor: pointer;
  transform: rotate(180deg);
`

export const StyledRegisterStepLabel = styled.div`
  ${({ theme }) => theme.typography.h2};
  margin-left: 16px;
`

export const StyledLinkRegisterBox = styled.a<{color?: string}>`
  color: ${({ theme, color }) => color === "blue" ? theme.colors.telemediBlueDark : theme.colors.telemediGreen};
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.875rem;
  margin: 0 4px;
`

export const StyledSslInfo = styled.div`
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.colors.telemediGray};
  font-size: 14px;
  padding-bottom: 35px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.telemediGray10};
  
  img {
    margin: 1px 10px 0 0;
  }
`

export const StyledLoginSection = styled.div<{color?: string}>`
  &,
  a {
    font-size: 13px;
  }
  
  margin-top: 38px;
  text-align: center;
  padding-top: 25px;
  border-top: 1px solid ${({ theme }) => theme.colors.telemediGray10};
  
  ${({ color }) => color === "light" && css`
      color: ${({ theme }) => theme.colors.telemediGray60};
      border: 0;
      margin-top: 18px;
      padding: 0;
  `}
`

export const StyledInfoBox = styled.div<{isFirstRegisterStep?: boolean}>`
  margin: ${({ isFirstRegisterStep }) => isFirstRegisterStep ? "12px auto 32px" : "12px auto"};
  padding: 12px;
  border: 2px solid ${({ theme }) => theme.colors.telemediRed};
  box-sizing: border-box;
  border-radius: 8px;
  text-align: left;
`

export const StyledButtonIcon = styled.span`
  display: inline-block;
  border: solid ${({ theme }) => theme.colors.telemediGreen};
  border-width: 0 2px 2px 0;
  padding: 6px;
  transform: rotate(135deg);
  position: relative;
  right: 8px;
  top: 2px;
`

export const StyledBackButton = styled.div`
  text-align: right;
  position: relative;
`

export const StyledCustomMessageHeader = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 64px;
`

export const StyledErrorIcon = styled.img`
  position: relative;
  top: 6px;
  margin-right: 8px;
`

export const StyledCustomMessageContent = styled.div`
  white-space: pre-line;
  color: ${({ theme }) => theme.colors.telemediGray60};
  margin-bottom: 50px;
  max-width: 460px;
`
