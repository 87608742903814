import api from "../api/api"
import {logInConfig} from "../api/routes"

type userDataType = {
    username: string,
    password: string
}

export const login = async (data: userDataType) => {
    try {
        const userData = await api.request({
            ...logInConfig,
            data,
        })

        return userData
    } catch (e) {
        console.error("unknown error.")
    }
}
