import React, { FC } from "react"
import i18next  from "i18next"
import { getFlagSrc } from "../../utils/Language.utils"

import {
  StyledSelectLanguageBox,
  StyledLanguageOption,
} from "../languageInfo/LanguageInfo.styles"

interface SelectLanguageProps {
  visible: boolean;
  languages: string[];
}

const SelectLanguage: FC<SelectLanguageProps> = ({visible, languages}) => {
  const currentLanguage = i18next.language
  const availableLanguages = Object.keys(i18next.services.resourceStore.data).filter(item => item !== currentLanguage && languages.includes(item))

  const handleChangeLanguage = (lang: string) => {
    window.location.href = `${window.location.origin}/${lang}/${window.location.search}`
  }

  const renderAvailableLanguages = () => {
    const languages: JSX.Element | JSX.Element[] = []

    availableLanguages.forEach((item) => {
      languages.push(
        <StyledLanguageOption
          key={item}
          onClick={() => handleChangeLanguage(item)}
        >
          <img src={getFlagSrc(item)} alt="" />
          { item }
        </StyledLanguageOption>
      )
    })

    return languages
  }

  return (
    <StyledSelectLanguageBox visible={visible}>
      { renderAvailableLanguages() }
    </StyledSelectLanguageBox>
  )
}

export default SelectLanguage
