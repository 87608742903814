import dayjs from "dayjs"

// instruction how to calculate birthdate: https://www.gov.pl/web/gov/czym-jest-numer-pesel
export const getBirthdateFromPesel = (pesel: string, isDifferentDateFormat?: boolean) => {
  const lastTwoYearDigits = Number(pesel.slice(0, 2))
  const centuryMonth = Number(pesel.slice(2, 4)) // 81-92 => 1800-1899, 01-12 => 1900-1999, 21-32 => 2000-2099, 41-52 => 2100-2199, 61-72 => 2200-2299
  const day = Number(pesel.slice(4, 6))

  const year = 1800 + (100 * (Math.ceil(centuryMonth / 20) % 5)) + lastTwoYearDigits
  const month = centuryMonth % 20

  const formattedMonth = month.toString().padStart(2, "0");
  const formattedDay = day.toString().padStart(2, "0");

  const dateFormat = isDifferentDateFormat ? "DD-MM-YYYY" : "YYYY-MM-DD"

  const fullDate = isDifferentDateFormat
      ? `${formattedDay}-${formattedMonth}-${year}`
      : `${year}-${formattedMonth}-${formattedDay}`

  const isDateValid = dayjs(fullDate, dateFormat, true).isValid()

  return isDateValid
    ? dayjs(fullDate, dateFormat).format(dateFormat)
    : undefined
}
