import styled from "styled-components"

export const StyledSelect = styled.select<{hasError: boolean}>`
    width: 100%;
    height: 100%;
    border: 2px solid ${({ theme, hasError }) => hasError ? theme.colors.telemediRed : theme.colors.telemediGray20};
    padding: 12px 20px 12px 12px;
    height: 48px;
    border-radius: 8px;
    font-size: 12px;
    
    
    
    @media ${({ theme }) => theme.breakpoints.small} {
      font-size: 16px;
    }
`
