import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import arrow from "../../assets/icons/arrow.svg"
import {
  StyledImg,
  StyledRegisterStepInfo,
  StyledRegisterStepLabel
} from "./RegisterForm.styles"

interface RegisterStepInfoProps {
  isFirstRegisterStep: boolean;
  setRegisterStep: React.Dispatch<React.SetStateAction<number>>;
}

const RegisterStepInfo: FC<RegisterStepInfoProps> = ({isFirstRegisterStep, setRegisterStep}) => {
  const { t } = useTranslation()

  if (isFirstRegisterStep) {
    return null
  }

  return (
    <StyledRegisterStepInfo>
      <StyledImg onClick={() => setRegisterStep(1)} src={arrow} alt="" />
      <StyledRegisterStepLabel>
        { t("personalDetails") }
      </StyledRegisterStepLabel>
    </StyledRegisterStepInfo>
  )
}

export default RegisterStepInfo
