export const typography = {
  fontFamily: [
    "'Ping 4'",
    "Arial",
    "sans-serif",
  ].join(),
  h1: {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing: "0.03em",
  },
  h2: {
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "48px",
    letterSpacing: "0.03em"
  },
  h3: {
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "48px",
    letterSpacing: "0.03em"
  },
}