import { createGlobalStyle } from "styled-components"
import { colors } from "./default/colors"

export const GlobalStyles = createGlobalStyle`
   * {
       margin: 0;
       padding: 0;
       outline: 0;
       box-sizing: border-box;
       font-family: 'Ping 4', sans-serif; 
   }
   
   .container {
      background: ${colors.telemediPageBackground};
      position: relative;
   }
`
