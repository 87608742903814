import React, {FC, useEffect, useState} from "react"
import {useSelector} from "react-redux"
import {getDashboardUrl} from "../../registerForm/RegisterForm.utils"
import {login} from "../../../services/LoginService"
import i18next from "i18next"
import signUp from "../../../assets/icons/signUp.svg"
import Button from "../../registerForm/common/button/Button.component"
import {selectClinicSettings} from "../../../store/clinicSettings/clinicSettings.selectors"
import { selectUserSettings } from "../../../store/userSettings/userSettings.selectors"
import { useTranslation } from "react-i18next"
import {
    StyledContainer,
    StyledHeaderBox,
    StyledHeader,
    StyledRegisterSuccess,
    StyledSignUpIcon,
    StyledMessage,
    StyledButtonContainer,
    StyledLinkRegisterBoxContainer
} from "./InfoBox.styles"
import {StyledLinkRegisterBox, StyledRegisterSuccessContent} from "../../registerForm/RegisterForm.styles"

const userProfileDashboardUrl = "user-profile#myProfile"

type InfoBoxProps = {
  sessionHash: string;
}

const InfoBox: FC<InfoBoxProps> = ({sessionHash}) => {
    const {t} = useTranslation()
    const clinicSettings = useSelector(selectClinicSettings)
    const userSettings = useSelector(selectUserSettings)
    const [loggingUser, setLoggingUser] = useState<boolean>(false)
    const insuranceVerificationEnabled = clinicSettings?.clinicSignalIdunaSettings?.insuranceVerificationEnabled
    const dashboardDestinationUrl =
        insuranceVerificationEnabled
            ? `${getDashboardUrl()}/${i18next.language}/${userProfileDashboardUrl}`
            : `${getDashboardUrl()}/${i18next.language}`

    useEffect(() => {
      if (sessionHash) {
        document.cookie = `sessionHash=${sessionHash}; path=/; domain=.${window.location.origin.replace(/^[^.]+\./g, "")}`
      }
    }, [sessionHash])

    return (
        <StyledContainer>
            <StyledHeaderBox>
                <StyledHeader>
                    {t("defaultTranslations:registerSuccess:welcome")}
                </StyledHeader>
            </StyledHeaderBox>
            <StyledRegisterSuccess>
                <StyledRegisterSuccessContent>
                    <StyledSignUpIcon src={signUp} alt=""/>
                    <div>{t("defaultTranslations:registerSuccess:header", {clinicName: clinicSettings?.frontendSettings?.seo?.title})}</div>
                </StyledRegisterSuccessContent>
              </StyledRegisterSuccess>
            <StyledMessage>
                {t(insuranceVerificationEnabled
                    ? "defaultTranslations:registerForm:customMessage:insuranceConfirmation"
                    : "defaultTranslations:registerSuccess:message")}
            </StyledMessage>
            <StyledButtonContainer>
                <Button
                    variant="contained"
                    type="button"
                    fullWidth={true}
                    loading={loggingUser}
                    handleClick={async() => {
                        setLoggingUser(true)

                        try {
                            const userData = await login({
                                username: userSettings.username,
                                password: userSettings.password
                            })
                            if (userData?.data?.token) {
                                window.location.href = dashboardDestinationUrl
                            }
                        } catch {
                            console.error("login error")
                        }
                    }}
                    label={t("defaultTranslations:registerSuccess:goToDashboard")}
                />
                <StyledLinkRegisterBoxContainer>
                    <StyledLinkRegisterBox href={`${getDashboardUrl()}/${i18next.language}/login`}>
                        { t("defaultTranslations:registerSuccess:goBack") }
                    </StyledLinkRegisterBox>
                </StyledLinkRegisterBoxContainer>
            </StyledButtonContainer>
        </StyledContainer>
    )
}

export default InfoBox
