import plFlag from "../assets/icons/flags/pl.svg"
import enFlag from "../assets/icons/flags/en.svg"
import esFlag from "../assets/icons/flags/es.svg"
import ptFlag from "../assets/icons/flags/pt.svg"
import ruFlag from "../assets/icons/flags/ru.svg"
import uaFlag from "../assets/icons/flags/ua.svg"
import ltFlag from "../assets/icons/flags/lt.svg"
import rsFlag from "../assets/icons/flags/rs.svg"
import csFlag from "../assets/icons/flags/cs.svg"
import frFlag from "../assets/icons/flags/fr.svg"
import itFlag from "../assets/icons/flags/it.svg"
import hrFlag from "../assets/icons/flags/hr.svg"
import baFlag from "../assets/icons/flags/ba.svg"
import bgFlag from "../assets/icons/flags/bg.svg"
import deFlag from "../assets/icons/flags/de.svg"

export enum Languages {
  PL = "pl",
  EN = "en",
  ES = "es",
  PT = "pt",
  RU = "ru",
  UA = "ua",
  LT = "lt",
  RS = "rs",
  CS = "cs",
  DE = "de",
  FR = "fr",
  IT = "it",
  HR = "hr",
  BA = "ba",
  BG = "bg"
}

export const getFlagSrc = (lang: string): string => {
  switch (lang) {
    case Languages.PL: return plFlag
    case Languages.EN: return enFlag
    case Languages.ES: return esFlag
    case Languages.PT: return ptFlag
    case Languages.RU: return ruFlag
    case Languages.UA: return uaFlag
    case Languages.LT: return ltFlag
    case Languages.RS: return rsFlag
    case Languages.CS: return csFlag
    case Languages.FR: return frFlag
    case Languages.IT: return itFlag
    case Languages.HR: return hrFlag
    case Languages.BA: return baFlag
    case Languages.BG: return bgFlag
    case Languages.DE: return deFlag
    default: return ""
  }
}
