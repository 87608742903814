import React, { FC, useState, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import i18next  from "i18next"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, SubmitHandler, FormProvider } from "react-hook-form"
import api from "../../api/api"
import { registerUserConfig, getCountriesListConfig, getEmailAddressExists } from "../../api/routes"
import useValidation from "hooks/UseValidation"
import RegisterStep1 from "./registerFormSteps/RegisterStep1.component"
import RegisterStep2 from "./registerFormSteps/RegisterStep2.component"
import CustomErrorMessage from "./CustomErrorMessage.component"
import ClinicLogo from "./ClinicLogo.component"
import ActionButtons from "./ActionButtons.component"
import LoginSection from "./LoginSection.component"
import RegisterStepInfo from "./RegisterStepInfo.component"
import ErrorList from "./ErrorList.component"
import {
  detectPatientCountry,
  getApiUrl,
  getDocumentTypesForCountry,
  scrollIntoError,
  watchBirthDate,
  checkScrollToError,
  handleChangeDocumentType,
  handleSetPrefixAndRenderDocumentsList,
  handleSetNoDocumentGivenAllowed, insuranceVerification,
} from "./RegisterForm.utils"
import { RegisterFormData, DocumentType, errorFieldTypes } from "./RegisterForm.types"
import { saveCountriesList } from "../../store/countries/countries.slice"
import { setUserIsRegistered, setUserData } from "../../store/userSettings/userSettings.slice"
import { voucherErrorName, errorTranslatePrefix, noDocumentAllowedCountry, defaultTimezone } from "constants/constants"
import { Languages } from "../../utils/Language.utils"
import { getClinicId } from "../../store/clinicSettings/clinicSettings.utils"
import { selectClinicSettings } from "../../store/clinicSettings/clinicSettings.selectors"
import { CustomErrorMessageType }  from "./RegisterForm.types"

/* eslint-disable react-hooks/exhaustive-deps */

import {
  StyledRegisterForm,
} from "./RegisterForm.styles"
import { V4ErrorType } from "../../enums/V4ErrorType";
import { replaceBadTimezones } from "../../utils/replaceBadTimezones";
import dayjs from "dayjs";

interface RegisterFormProps {
  children: Element | Element[] | unknown;
}

const RegisterForm: FC<RegisterFormProps> = ({children}) => {
  const formRef = useRef(null)
  const errorRef = useRef<null | HTMLDivElement>(null)
  const { t } = useTranslation()
  const language = i18next.language
  const [registerStep, setRegisterStep] = useState<number>(1)
  const [countriesList, setCountriesList] = useState<JSX.Element | JSX.Element[]>([])
  const [documentTypeOptions, setDocumentTypeOptions] = useState<JSX.Element | JSX.Element[]>([])
  const [errorList, setErrorList] = useState<string[]>([])
  const [customErrorMessage, setCustomErrorMessage] = useState<CustomErrorMessageType>(null)
  const { registerSchemaStep1, registerSchemaStep2 } = useValidation()
  const [documentFieldName, setDocumentFieldName] = useState<string>("identityNumber")
  const [documentTypeListVisible, setDocumentTypeListVisible] = useState<boolean>(true)
  const [documentValueVisibility, setDocumentValueVisibility] = useState<boolean>(true)
  const [noDocumentGivenAllowed, setNoDocumentGivenAllowed] = useState<boolean>(false)
  const [noDocumentTypeOption, setNoDocumentTypeOption] = useState<JSX.Element | JSX.Element[]>([])
  const [idNumberFieldDisabled, setIdNumberFieldDisabled] = useState<boolean>(true)
  const [selectedCountryName, setSelectedCountryName] = useState<string>("")
  const [detectedCountry, setDetectedCountry] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)
  const [prefixCodeFlagSrc, setPrefixCodeFlagSrc] = useState<string>("")
  const isFirstRegisterStep = registerStep === 1
  const clinicSettings = useSelector(selectClinicSettings)
  const dispatch = useDispatch()

  const {
    frontendSettings: { modules }
  } = useSelector(selectClinicSettings)

  const isDifferentDateFormatEnabled = modules.includes("enable_different_date_format")

  const form = useForm<RegisterFormData>({
    criteriaMode: "all",
    mode: "all",
    resolver: yupResolver(
        isFirstRegisterStep
            ? registerSchemaStep1
            : registerSchemaStep2(isDifferentDateFormatEnabled)
    ),
    defaultValues: {
      termsAccepted: false,
      collectMedicalDocument: false
    }
  })
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    setValue,
    control,
  } = form

  const setFormError = (fieldName: errorFieldTypes, key: string) => {
    setError(fieldName, {
        type: "manual",
        message: t(`validation:${key}`)
      },
      {
        shouldFocus: true
      }
    )
  }

  const handleChangeBirthDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const birthDateValue = event?.target?.value
    const nationality = (document.getElementsByName("nationality")?.[0] as HTMLSelectElement)?.value

    handleSetNoDocumentGivenAllowed(
        birthDateValue,
        nationality,
        setNoDocumentGivenAllowed,
        isDifferentDateFormatEnabled
    )
  }

  const onSubmitFirstStep: SubmitHandler<RegisterFormData> = async (data) =>  {
      setLoading(true)
      setErrorList([])

      try {
        const emailExists = await api.request({
          ...getEmailAddressExists(data?.email),
          baseURL: getApiUrl(),
        })

        if (emailExists?.data?.id) {
          setErrorList([`${errorTranslatePrefix}:email`])
        }
        setLoading(false)
        scrollIntoError(errorList, errorRef)
      } catch (e) { // no patient found with given email address, go to next step
        setLoading(false)
        setRegisterStep(2)
      }
  }

  const onSubmitSecondStep: SubmitHandler<RegisterFormData> = async data => {
    const errorData = []
    const documentIsPesel = documentFieldName === "pesel"
    const documentIsPassport = documentFieldName === "identityNumber"

    setLoading(true)
    setErrorList([])

    if (!data.collectMedicalDocument && clinicSettings?.collectMedicalDocument || !data.termsAccepted) {
      errorData.push(t("validation:checkRules"))
      setErrorList(errorData)
      setLoading(false)
      scrollIntoError(errorList, errorRef)
      return false
    }

    data.phoneNumber = `+${data.prefixCode}${data.phoneNumber}`
    data.prefixCode = `+${data.prefixCode}`
    data.confirmDuplicateEmail = false
    data.identityDocumentCountryIssuer = data.nationality
    data.locale = language
    data.defaultTimezone = replaceBadTimezones(Intl.DateTimeFormat().resolvedOptions().timeZone ?? defaultTimezone)

    if (!documentFieldName || documentIsPesel) {
      data.identityDocumentCountryIssuer = ""
      data.identityNumber = ""
      data.identityDocumentType = ""
      data.passportNumber = ""
      data.pesel = documentIsPesel ? data.pesel : ""
    } else {
      data.pesel = ""
      data.passportCountry = documentIsPassport ? data.nationality : ""
    }

    if (data.birthDate && isDifferentDateFormatEnabled) {
      data.birthDate = dayjs(data.birthDate, "DD-MM-YYYY").format("YYYY-MM-DD")
    }

    try {
      const userData = await api.request({
        ...registerUserConfig,
        data,
        baseURL: getApiUrl(),
      })

      if (userData.status === 200) {
        errorData.push(`${errorTranslatePrefix}:email`)
        setErrorList(errorData)
        scrollIntoError(errorList, errorRef)
      } else if (userData.status === 201) {
        dispatch(setUserIsRegistered())
        dispatch(setUserData({
          username: data.email,
          password: data.plainPassword
        }))
      }
      setLoading(false)
    } catch (error) {
      const errorsData = error.response?.data
      const errors = errorsData?.errors

      if (errorsData.errorType === V4ErrorType.VOUCHER_ERROR) {
        setFormError("voucher", "apiError:voucher")
      }

      insuranceVerification(errorsData?.errorType, setCustomErrorMessage)

      if (errors) {
        for (const errorItem of Object.keys(errors)) {
          if (errorItem === voucherErrorName) {
            setFormError("voucher", "apiError:voucher")
          } else {
            errorData.push(`${errorTranslatePrefix}:${errorItem}`)
          }
        }

        setErrorList(errorData)
        scrollIntoError(errorList, errorRef)
      }
      setLoading(false)
    }
  }

  const initCountriesList = async () => {
    const countriesData = await api.request({
      ...getCountriesListConfig,
      baseURL: getApiUrl(),
    })
    const { data: { countries } } = countriesData
    const countriesOptions: JSX.Element[] = []

    dispatch(saveCountriesList(countriesData))

    for (const country of Object.keys(countries)) {
      countriesOptions.push(
        <option
          key={country}
          value={country}
        >
          {countries[country]}
        </option>
      )
    }

    setCountriesList(countriesOptions)
  }

  const renderDocumentTypeOptions = (country: string) => {
    const documentTypeOptions: JSX.Element[] = []
    const documentsOptionsList = getDocumentTypesForCountry(country)
    const onlyPeselField = country.toLowerCase() === Languages.PL.toLowerCase()

    setValue("identityDocumentType", "")
    setIdNumberFieldDisabled(!onlyPeselField)
    if (onlyPeselField) {
      setDocumentTypeListVisible(false)
      setDocumentFieldName("pesel")
      return
    }

    setDocumentTypeListVisible(true)

    documentTypeOptions.push(
      <option
        key="select"
        value=""
      >
        {t("defaultTranslations:registerForm:document:selectDocumentType")}
      </option>
    )

    documentsOptionsList.forEach((item, index) => {
      if (
          language !== Languages.PL.toLowerCase() &&
          item.type === DocumentType.PESEL &&
          clinicSettings?.widgetRegisterSettings?.hidePeselOption
      ) {
        return;
      }
      else {
        documentTypeOptions.push(
            <option
                key={index}
                value={item.type}
            >
              {item.label}
            </option>
        )
      }
    })

    setDocumentTypeOptions(documentTypeOptions)
  }

  const handleOnChangeNationality = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedNationality = event?.target?.value

    if (selectedNationality !== noDocumentAllowedCountry.toUpperCase()) {
      setNoDocumentGivenAllowed(false)
    } else {
      handleSetNoDocumentGivenAllowed(getValues?.("birthDate"), selectedNationality, setNoDocumentGivenAllowed)
    }

    setDocumentFieldName("identityNumber")
    setValue("identityNumber", "")
    renderDocumentTypeOptions(selectedNationality)
  }

  const changeDocumentType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    handleChangeDocumentType(event, setIdNumberFieldDisabled, setDocumentValueVisibility, setDocumentFieldName)
    setValue("pesel", "")
    setValue("identityNumber", "")
  }

  useEffect(() => {
    if (!getClinicId() || clinicSettings?.domain) {
      detectPatientCountry(setDetectedCountry, initCountriesList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicSettings?.domain])

  useEffect(() => {
    const select = (document.getElementById("nationality") as HTMLSelectElement)
    const selectedNationality = (select?.options?.[select?.selectedIndex])?.text

    setSelectedCountryName(selectedNationality)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(document.getElementsByName("nationality")?.[0] as HTMLSelectElement)?.innerHTML])

  useEffect(() => {
    const documentsList = []
    const noDocumentOption =
      <option
        key={DocumentType.NO_DOCUMENT}
        value={DocumentType.NO_DOCUMENT}
      >
        {t("defaultTranslations:registerForm:document:noDocument")}
      </option>

    if (noDocumentGivenAllowed) {
      documentsList.push(noDocumentOption)
    }

    setNoDocumentTypeOption(documentsList)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noDocumentGivenAllowed])

  useEffect(() => {
    checkScrollToError(errors)
  }, [errors])

  useEffect(() => {
    if (registerStep === 1) {
      setErrorList([])
      return
    }

    if (registerStep === 2) {
      handleSetPrefixAndRenderDocumentsList(setPrefixCodeFlagSrc, renderDocumentTypeOptions)
      watchBirthDate(watch, setNoDocumentGivenAllowed, isDifferentDateFormatEnabled)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, registerStep])

  if (customErrorMessage) {
    return (
      <>
        <ClinicLogo />
        <StyledRegisterForm>
          <CustomErrorMessage
              customErrorMessage={customErrorMessage}
              handleSetCustomError={setCustomErrorMessage}
          />
        </StyledRegisterForm>
      </>
    )
  }

  return (
          <>
            {children}
            <StyledRegisterForm>
              <ErrorList isFirstRegisterStep={isFirstRegisterStep} errorRef={errorRef} errorList={errorList} />
              <RegisterStepInfo isFirstRegisterStep={isFirstRegisterStep} setRegisterStep={setRegisterStep} />
              <FormProvider {...form}>
                <form
                    onSubmit={handleSubmit(isFirstRegisterStep ? onSubmitFirstStep : onSubmitSecondStep)}
                    ref={formRef}
                >
                  {
                    isFirstRegisterStep
                        ? <RegisterStep1 register={register} errors={errors} setValue={setValue} />
                        : <RegisterStep2
                            register={register}
                            errors={errors}
                            getValues={getValues}
                            setValue={setValue}
                            handleOnChangeNationality={handleOnChangeNationality}
                            handleChangeBirthDate={handleChangeBirthDate}
                            changeDocumentType={changeDocumentType}
                            countriesList={countriesList}
                            documentTypeOptions={documentTypeOptions}
                            documentFieldName={documentFieldName}
                            idNumberFieldDisabled={idNumberFieldDisabled}
                            documentTypeListVisible={documentTypeListVisible}
                            noDocumentTypeOption={noDocumentTypeOption}
                            documentValueVisibility={documentValueVisibility}
                            selectedCountryName={selectedCountryName}
                            setSelectedCountryName={setSelectedCountryName}
                            detectedCountry={detectedCountry}
                            prefixCodeFlagSrc={prefixCodeFlagSrc}
                            setPrefixCodeFlagSrc={setPrefixCodeFlagSrc}
                            control={control}
                        />
                  }
                  <ActionButtons
                      loading={loading}
                      isFirstRegisterStep={isFirstRegisterStep}
                      setRegisterStep={setRegisterStep}
                  />
                </form>
              </FormProvider>
              <LoginSection isFirstRegisterStep={isFirstRegisterStep} />
            </StyledRegisterForm>
          </>
       )
}

export default RegisterForm
