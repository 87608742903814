import React, { FC, useState } from "react"
import { RegisterFormTypes } from "../../RegisterForm.types"
import eyeOn from "../../../../assets/icons/eyeOn.svg"
import eyeOff from "../../../../assets/icons/eyeOff.svg"
import Input from "../input/Input.component"
import { StyledIconContainer } from "./Password.styles"

interface PasswordProps extends RegisterFormTypes {
    setValue?(name: string, value: string): void;
}

const Password: FC<PasswordProps> = ({placeholder, name, register, errors, setValue}) => {
  const [passwordIsVisible, setPasswordIsVisible] = useState<boolean>(false)

  return (
    <>
      <Input
        type={passwordIsVisible ? "text" : "password"}
        name={name}
        placeholder={placeholder}
        errors={errors}
        register={register}
        onChange={(e) => setValue?.(name, e?.target?.value)}
      />
      <StyledIconContainer
        onClick={() => setPasswordIsVisible(!passwordIsVisible)}
      >
        <img src={passwordIsVisible ? eyeOff : eyeOn} alt="" />
      </StyledIconContainer>
    </>
  )
}

export default Password
