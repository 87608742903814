import React, {FC} from "react"
import { useTranslation } from "react-i18next"
import Button from "./common/button/Button.component"
import {
  StyledActionButtons,
} from "./RegisterForm.styles"

interface ActionButtonsProps {
  loading: boolean;
  isFirstRegisterStep: boolean;
  setRegisterStep: React.Dispatch<React.SetStateAction<number>>
}

const ActionButtons: FC<ActionButtonsProps> = ({loading, setRegisterStep, isFirstRegisterStep}) => {
  const { t } = useTranslation()

  return (
    <StyledActionButtons>
      <Button
        variant="outlined"
        type="button"
        fullWidth={false}
        loading={loading}
        handleClick={!isFirstRegisterStep ? () => setRegisterStep(1) : undefined}
        label={t("defaultTranslations:registerForm:button:cancel")}
      />
      <Button
        variant="contained"
        fullWidth={false}
        loading={loading}
        type="submit"
        label={t(`defaultTranslations:registerForm:button:${isFirstRegisterStep ? "next" : "createAccount"}`)}
      />
    </StyledActionButtons>
  )
}

export default ActionButtons
