import React, { FC, ReactNode } from "react"
import Input from "../input/Input.component"
import {RegisterFormTypes} from "../../RegisterForm.types"
import {
  StyledCheckboxContainer,
  StyledInputWrapper,
  StyledLabelWrapper,
  StyledCheckedCheckbox,
} from "./CheckBox.styles"
import { useFormContext } from "react-hook-form";

interface CheckBoxProps extends RegisterFormTypes {
  label: ReactNode;
}

const CheckBox: FC<CheckBoxProps> = ({
  register,
  errors,
  label,
  name,
  validation = {},
}) => {
  const formMethods = useFormContext()
  const isCheckboxChecked = formMethods.watch(name)

  return (
    <>
      <StyledCheckboxContainer>
        <StyledInputWrapper>
          { isCheckboxChecked && <StyledCheckedCheckbox></StyledCheckedCheckbox> }
          <Input
            name={name}
            label={label}
            type="checkbox"
            register={register}
            errors={errors}
            validation={validation}
          />
        </StyledInputWrapper>
        <StyledLabelWrapper>
          <label htmlFor={name}>{label}</label>
        </StyledLabelWrapper>
      </StyledCheckboxContainer>
    </>
  )
}

export default CheckBox
