import * as yup from "yup"
import { useTranslation } from "react-i18next"
import { DocumentType }  from "../components/registerForm/RegisterForm.types"
import { Languages } from "../utils/Language.utils"
import { birthDateRegExp, signalIdunaInsuranceNumber, differentFormatBirthDateRegExp } from "constants/constants"
import { useSelector } from "react-redux";
import { selectClinicSettings } from "../store/clinicSettings/clinicSettings.selectors";
import { getBirthdateFromPesel } from "utils/getBirthdateFromPesel/getBirthdateFromPesel"
import dayjs from "dayjs"

import customParseFormat from "dayjs/plugin/customParseFormat"

dayjs.extend(customParseFormat)

const useValidation = (): yup.InferType<typeof registerSchemaStep1|typeof registerSchemaStep2> => {
  const { t } = useTranslation()
  const validationPrefix = "validation"
  const phoneRegExp = new RegExp("^[0-9]{7,15}$")
  const prefixCodexRegExp = new RegExp("^[0-9]{1,3}$")
  const peselRegExp = /^[0-9]{11}$/
  const passwordRegExp = new RegExp("^(?=.*[a-zżźćńółęąś])(?=.*[A-ZŻŹĆĄŚĘŁÓŃ])(?=.*[\\d])(?=.*[~!@#$%\\^*\\(\\)\\-_=+\\{\\}\\[\\]\\|:;'\",./\\?€£¥₹])[a-zżźćńółęąśA-ZŻŹĆĄŚĘŁÓŃ\\d~!@#$%\\^*\\(\\)\\-_=+\\{\\}\\[\\]\\|:;'\",./\\?€£¥₹]{8,}$")
  const nameRegexp = /^[a-zA-Z\-_ ’'‘ÆÐƎƏƐƔĲŊŒẞÞǷȜæðǝəɛɣĳŋœĸſßþƿȝĄƁÇĐƊĘĦĮƘŁØƠŞȘŢȚŦŲƯY̨Ƴąɓçđɗęħįƙłøơşșţțŧųưy̨ƴÁÀÂÄǍĂĀÃÅǺĄÆǼǢƁĆĊĈČÇĎḌĐƊÐÉÈĖÊËĚĔĒĘẸƎƏƐĠĜǦĞĢƔáàâäǎăāãåǻąæǽǣɓćċĉčçďḍđɗðéèėêëěĕēęẹǝəɛġĝǧğģɣĤḤĦIÍÌİÎÏǏĬĪĨĮỊĲĴĶƘĹĻŁĽĿʼNŃN̈ŇÑŅŊÓÒÔÖǑŎŌÕŐỌØǾƠŒĥḥħıíìiîïǐĭīĩįịĳĵķƙĸĺļłľŀŉńn̈ňñņŋóòôöǒŏōõőọøǿơœŔŘŖŚŜŠŞȘṢẞŤŢṬŦÞÚÙÛÜǓŬŪŨŰŮŲỤƯẂẀŴẄǷÝỲŶŸȲỸƳŹŻŽẒŕřŗſśŝšşșṣßťţṭŧþúùûüǔŭūũűůųụưẃẁŵẅƿýỳŷÿȳỹƴźżžẓ\s]+$/
  const clinicSettings = useSelector(selectClinicSettings)

    const isValidBirthDate = (birthDate?: string, isDifferentDateFormat?: boolean) => {
        const dateFormat = isDifferentDateFormat ? "DD-MM-YYYY" : "YYYY-MM-DD"
        const earliestDate = dayjs(isDifferentDateFormat ? "31-12-1899" : "1899-12-31", dateFormat)
        const oldestDate = dayjs(new Date()).format(dateFormat)
        const formattedBirthDate = dayjs(birthDate, dateFormat)
        const parsedOldestDate = dayjs(oldestDate, dateFormat)

        return !(
            !formattedBirthDate.isValid()
            || !formattedBirthDate.isAfter(earliestDate)
            || !formattedBirthDate.isBefore(parsedOldestDate)
        )
    }

  const registerSchemaStep1 = yup.object().shape({
    email: yup
      .string()
      .email(t(`${validationPrefix}:email`))
      .required(t(`${validationPrefix}:email`)),
    plainPassword: yup
      .string()
      .matches(passwordRegExp, t(`${validationPrefix}:incorrectPasswordPattern`)),
    plainPasswordRepeat: yup
      .string()
      .oneOf([yup.ref("plainPassword"), null], t(`${validationPrefix}:passwordMustMatch`))
  }).required()

  const registerSchemaStep2 = (isDifferentDateFormat?: boolean) => {
    const birthDateRegexp = isDifferentDateFormat ? differentFormatBirthDateRegExp : birthDateRegExp

    return yup.object().shape({
      firstName: yup
          .string()
          .matches(nameRegexp, t(`${validationPrefix}:incorrectFirstNamePattern`))
          .required(t(`${validationPrefix}:required`)),
      lastName: yup
          .string()
          .matches(nameRegexp, t(`${validationPrefix}:incorrectLastNamePattern`))
          .required(t(`${validationPrefix}:required`)),
      identityNumber: yup
          .string()
          .when("identityDocumentType", {
            is: (val: string) => (val && val === DocumentType.IDENTITY_CARD_NUMBER),
            then: yup.string()
                .required(t(`${validationPrefix}:required`))
          }),
      passportNumber: yup
          .string()
          .when("identityDocumentType", {
            is: (val: string) => (val && val === DocumentType.PASSPORT),
            then: yup.string()
                .required(t(`${validationPrefix}:required`))
          }),
      identityDocumentType: yup
          .string()
          .when("nationality", {
            is: (val: string) => (val && val !== Languages.PL.toUpperCase()),
            then: yup.string()
                .required(t(`${validationPrefix}:required`))
          }),
      voucher: yup
          .string()
          .test("requireCoupon", t(`${validationPrefix}:required`), (value?: string) => {
            if (clinicSettings?.requireCoupon) {
              return !!value
            }

            return true
          }),
      birthDate: yup
          .string()
          .matches(birthDateRegexp, t(`${validationPrefix}:` + (isDifferentDateFormat ? "differentFormatBirthDate" : "birthDate")))
          .test("is-valid-birth-date", t(`${validationPrefix}:` + (isDifferentDateFormat ? "differentFormatBirthDateInvalid" : "birthDateInvalid")), (value?: string) =>
              isValidBirthDate(value, isDifferentDateFormat),
          )
          // @ts-ignore: no-explicit-any
          .when("pesel", (pesel: string, schema: yup.InferType<typeof registerSchemaStep1>, birthDate: any) => {
            const isValidPesel = pesel && pesel.match(peselRegExp)
            const isValidDate = birthDate.value && birthDate.value.match(birthDateRegexp)

            if (isValidPesel && isValidDate && isValidBirthDate(birthDate.value, isDifferentDateFormat)) {
              const birthDateFromPesel = getBirthdateFromPesel(pesel, isDifferentDateFormat)

              return schema.test("dates-matching", t(`${validationPrefix}:dateOfBirthNotMatch`), (value?: string) => {
                  const dateFormat = isDifferentDateFormat ? "DD-MM-YYYY" : "YYYY-MM-DD";
                  const parsedBirthDateFromPesel = dayjs(birthDateFromPesel, dateFormat, true);
                  const parsedValue = dayjs(value, dateFormat, true);

                  return parsedBirthDateFromPesel.isSame(parsedValue, "day")
              })
            }

            return schema
          }),
      phoneNumber: yup
          .string()
          .matches(phoneRegExp, t(`${validationPrefix}:phone`)),
      pesel: yup
          .string()
          .required(t(`${validationPrefix}:required`))
          .matches(peselRegExp, t(`${validationPrefix}:pesel`)),
      prefixCode: yup
          .string()
          .required(t(`${validationPrefix}:required`))
          .matches(prefixCodexRegExp, t(`${validationPrefix}:prefixCode`)),
      insuranceNumber: yup
          .string()
          .when("nationality", {
            is: (val: string) => (val && val !== Languages.PL.toUpperCase()),
            then: yup.string()
                .matches(signalIdunaInsuranceNumber, "")
          })
    }).required().when((values: string, schema: any) => {
      for (const key in schema.fields) {
        const currentField = document.getElementsByName(key)

        if (currentField?.[0]?.dataset?.required === "false" || !currentField.length) {
          delete schema.fields[key]
        }
      }
    })
  }

  return {
    registerSchemaStep1,
    registerSchemaStep2,
  }
}

export default useValidation
