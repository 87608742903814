import React, { FC, useEffect, useState } from "react"
import {Controller, Control, FieldValues} from "react-hook-form"
import Select from "react-select"
import {useSelector, useDispatch} from "react-redux"
import api from "../../../../api/api"
import {getApiUrl} from "../../RegisterForm.utils"
import {RegisterFormTypes, PhoneCountryCodes, CountryCodesSelect} from "../../RegisterForm.types"
import { getPhoneCountryCodesConfig } from "../../../../api/routes"
import { setPrefixCode } from "../../../../store/userSettings/userSettings.slice"
import {selectCountriesList} from "../../../../store/countries/countries.selectors"
import { selectUserSettings } from "../../../../store/userSettings/userSettings.selectors"
import { StyledPhonePrefix } from "./PrefixCode.styles"

interface PrefixCodeProps extends RegisterFormTypes {
  setValue(name: string, value: string): void;
  selectedCountryName?: string;
  prefixCodeFlagSrc?: string;
  setPrefixCodeFlagSrc: React.Dispatch<React.SetStateAction<string>>;
  control: Control<FieldValues>;
}

const PrefixCode: FC<PrefixCodeProps> = (
  {
    register,
    errors,
    name,
    setValue,
    selectedCountryName,
    prefixCodeFlagSrc,
    setPrefixCodeFlagSrc,
    control,
  }
) => {
  const [countryCodesData, setCountryCodesData] = useState<PhoneCountryCodes | Record<string, never>>()
  const [countriesOptions, setCountriesOptions] = useState<CountryCodesSelect[]>([])
  const [prefixCodeValue, setPrefixCodeValue] = useState<string>("")
  const [showFlag, setShowFlag] = useState<boolean>(true)
  const countriesList = useSelector(selectCountriesList)
  const userSettings = useSelector(selectUserSettings)
  const dispatch = useDispatch()

  const getPhoneCountryCodes = async () => {
    const {data: {codes: countryCodes}} = await api.request<{codes: PhoneCountryCodes}>({
      ...getPhoneCountryCodesConfig,
      baseURL: getApiUrl(),
    })

    setCountryCodesData(countryCodes)
  }

  const setPrefixCodeForCountry = () => {
    if (countryCodesData && selectedCountryName && !userSettings.prefixCodeSet) {
      const data = Object.values(countryCodesData).filter((item) => {
        return item.includes(selectedCountryName)
      })
      const value = data.toString().match(/\((.*)\)/)?.[1] || "" // get country code for current selection

      setValue(name, value.replace("+", ""))
      setPrefixCodeValue(value)
      dispatch(setPrefixCode())
    }
  }

  const handleOnChange = (prefixCode: string) => {
    if (countryCodesData) {
      const data: string[] = Object.values(countryCodesData).filter((item) => {
        return item.includes(prefixCode)
      })

      if (data && data.length === 1) {
        const countryCode = Object.values(countriesList).filter((item: any) => {
          return item.includes(data?.[0]?.replace(/ *\([^)]*\) */g, ""))
        })

        setPrefixCodeFlagSrc(`/flags-register-form/${Object.keys(countriesList).find(key => countriesList[key]?.includes?.(countryCode?.[0]))}.png`.toLowerCase())
      }
    }
  }

  useEffect(() => {
    const countryCodesList = []

    if (!countryCodesData) {
      getPhoneCountryCodes()
    } else {
      setPrefixCodeForCountry()

      for (const code in countryCodesData) {
        countryCodesList.push({
          value: code,
          label: countryCodesData[code]
        })
      }

      setCountriesOptions(countryCodesList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCodesData, setValue, selectedCountryName])

  if (!countriesOptions) {
    return null
  }

  return (
    <StyledPhonePrefix
      prefixCodeFlagSrc={prefixCodeFlagSrc}
      hasError={errors?.prefixCode}
      showFlag={showFlag}
    >
      <Controller
          control={control}
          {...register(name, {})}
          render={({field}) => (
            <Select
              {...field}
              onChange={(option: any) => { // type bug in react-select
                setValue(name, option.value.replace("+", ""))
                setPrefixCodeValue(option.value)
                handleOnChange(option.value)
                setShowFlag(true)
              }}
              options={countriesOptions}
              id={name}
              name={name}
              data-required={true}
              placeholder="..."
              components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
              value={{label: prefixCodeValue}}
              onMenuOpen={() => {
                setPrefixCodeValue("")
                setValue(name, "")
                setShowFlag(false)
              }}
            />
          )}
      />
    </StyledPhonePrefix>
  )
}

export default PrefixCode
