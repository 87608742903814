import {combineReducers} from "@reduxjs/toolkit"
import countriesReducer from "./countries/countries.slice"
import clinicSettingsReducer from "./clinicSettings/clinicSettings.slice"
import userSettingsReducer from "./userSettings/userSettings.slice"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const createRootReducer = () => combineReducers({
  countries: countriesReducer,
  clinic: clinicSettingsReducer,
  userSettings: userSettingsReducer,
})

export default createRootReducer
