import styled, {css} from "styled-components"

interface StyledButtonProps {
    isLoading: boolean,
    variant: string
    fullWidth: boolean;
}

export const StyledButton = styled.button<StyledButtonProps>`
    font-size: 16px;
    line-height: 150%;
    border-radius: ${({ theme }) => theme.buttons.borderRadius+"px"};
    outline: none;
    border: 2px solid transparent;
    padding: 10px 32px;
    cursor: pointer;
    transition: .15s cubic-bezier(0.66, 0.01, 0.86, 1);
    display: inline-block;
    text-decoration: none;
    font-weight: 500;
    
    @media ${({ theme }) => theme.breakpoints.semiTablet} {
        width: auto;
    }
    
    &:hover {
      & > span {
        border: solid ${({ theme }) => theme.colors.telemediWhite};
        border-width: 0 2px 2px 0;
      }
    }
    
    ${({ isLoading }) => isLoading && css`
        opacity: .4;
        pointer-events: none;
    `}

    ${({ variant }) => variant === "contained" && css`
        color: ${({ theme }) => theme.colors.telemediWhite};
        background-color: ${({ theme }) => theme.colors.telemediGreen};
        box-shadow: none;
        
        &:hover {
           background-color: ${({ theme }) => theme.colors.telemediGreenDark};
           box-shadow: 2px 2px 2px ${({ theme }) => theme.colors.telemediWhite};
        }
    `}
    
    ${({ variant }) => variant === "outlined" && css`
        color: ${({ theme }) => theme.colors.telemediGreen};
        background-color: ${({ theme }) => theme.colors.telemediWhite};
        box-shadow: none;
        border: 2px solid ${({ theme }) => theme.colors.telemediGreen};
        margin-right: 20px;
        
        &:hover {
            background-color: ${({ theme }) => theme.colors.telemediGreen};
            color: ${({ theme }) => theme.colors.telemediWhite};
            border: 2px solid ${({ theme }) => theme.colors.telemediGreen};
        }
    `}
    
    ${({ fullWidth }) => fullWidth && css`
        width: 100%!important;
    `}
`
