import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import Button from "./common/button/Button.component"
import {CustomErrorMessageType} from "./RegisterForm.types"
import errorIcon from "../../assets/icons/error.svg"
import {
  StyledButtonIcon,
  StyledBackButton,
  StyledCustomMessageHeader,
  StyledErrorIcon,
  StyledCustomMessageContent,
} from "./RegisterForm.styles"

interface CustomErrorMessageProps {
  customErrorMessage: CustomErrorMessageType;
  handleSetCustomError: React.Dispatch<React.SetStateAction<CustomErrorMessageType>>;
}

const CustomErrorMessage: FC<CustomErrorMessageProps> = ({customErrorMessage, handleSetCustomError}) => {
    const { t } = useTranslation()

    return (
      <>
        <StyledCustomMessageHeader>
            <StyledErrorIcon src={errorIcon} />
            {customErrorMessage?.title}
        </StyledCustomMessageHeader>
        <StyledCustomMessageContent>
           {customErrorMessage?.message}
        </StyledCustomMessageContent>
         <StyledBackButton
           onClick={() => handleSetCustomError(null)}
         >
             <Button
               variant="outlined"
               fullWidth={false}
               loading={false}
               type="button"
               label={t("defaultTranslations:registerForm:back")}
               buttonIcon={<StyledButtonIcon/>}
             />
         </StyledBackButton>
      </>
    )
}

export default CustomErrorMessage
