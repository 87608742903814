import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { RegisterFormTypes } from "../../RegisterForm.types"
import Input from "../input/Input.component"

interface EmailProps extends RegisterFormTypes {}

const Email: FC<EmailProps> = ({register, errors, name}) => {
  const { t } = useTranslation()

  return (
    <Input
      type="email"
      name={name}
      errors={errors}
      placeholder={t("defaultTranslations:registerForm:placeholder:email")}
      register={register}
    />
  )
}

export default Email
