import styled from "styled-components"

export const StyledVoucherSection = styled.div`
  margin: 45px 0 40px;
`

export const StyledVoucherCodeLabel = styled.div`
  ${({ theme }) => theme.typography.h1};
  padding-bottom: 24px;
  margin-bottom: 30px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.telemediGray10};
`
