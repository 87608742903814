import styled from "styled-components"

export const StyledContainer = styled.div`
    margin: 80px 15px 0 15px;
    padding: 40px 56px;
    background: ${({ theme }) => theme.colors.telemediWhite};
    max-width: 620px;
    border-radius: 28px;
    padding: 40px 15px;
    
    @media ${({ theme }) => theme.breakpoints.small} {
        padding: 40px 56px;
        margin: 80px auto 0 auto;
    }
`

export const StyledHeaderBox = styled.div`
    padding-bottom: 24px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.telemediGray10};
`

export const StyledHeader = styled.div`
    ${({ theme }) => theme.typography.h1};
`

export const StyledRegisterSuccess = styled.div`
    ${({ theme }) => theme.typography.h2};
    text-align: center;
    margin-top: 12px;
    line-height: 24px;
    
    @media ${({ theme }) => theme.breakpoints.small} {
        text-align: initial;
    }
`

export const StyledSignUpIcon = styled.img`
    position: relative;
    top: -10px;
    margin-right: 15px;
    display: block;
    
    @media ${({ theme }) => theme.breakpoints.small} {
        top: -4px;
        display: initial;
    }
`

export const StyledMessage = styled.div`
    text-align: center;
    margin: 30px 0;
    white-space: pre-line;
    
    @media ${({ theme }) => theme.breakpoints.small} {
        text-align: initial;
        margin: 30px 0 0 63px;
    }
`

export const StyledButtonContainer = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 40px;
`

export const StyledLinkRegisterBoxContainer = styled.div`
    margin-top: 29px;
`

