import styled from "styled-components"

export const StyledPhonePrefix = styled.div<{showFlag?: boolean; hasError?: boolean; prefixCodeFlagSrc?: string}>`
  width: 90px;
  margin-right: 10px;
  position: relative;
  
  > div > div {
    width: 100%;
    z-index: 3;
    border: 2px solid ${({ theme, hasError }) => hasError ? theme.colors.telemediRed : theme.colors.telemediGray20};
    padding: 17px 12px 1px 0;
    border-radius: 8px;
    font-size: 16px;
    
    & > div {
      padding-left: 30px;
      position: relative;
      top: -4px;
    }
    
    &:hover,
    &:focus,
    &:active {
      border: 2px solid ${({ theme }) => theme.colors.telemediGreen}!important;
      box-shadow: none!important;
    }
    &,
    ::placeholder,
    ::-webkit-input-placeholder {
       color: ${({ theme }) => theme.colors.telemediBlack}!important;
       font-size: 12px;
    };
    
    @media ${({ theme }) => theme.breakpoints.small} {
      &,
      ::placeholder,
      ::-webkit-input-placeholder {
        font-size: 16px;
        padding: 12px 12px 1px 0;
      };
    }
  }

  &:before {
    background: ${({ prefixCodeFlagSrc }) => `url(${prefixCodeFlagSrc}) no-repeat;`};
    content: "";
    display: ${({ showFlag }) => showFlag ? "block" : "none"};
    height: 15px;
    top: 17px;
    position: absolute;
    z-index: 4;
    width: 21px;
    background-size: 20px;
    left: 10px;
  }
`
