import styled from "styled-components"

export const StyledCheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  position: relative;
`

export const StyledLabelWrapper = styled.div`
  width: 100%;
  font-size: 14px;
  > label {
    z-index: 2;
    position: relative;
  }
`

export const StyledInputWrapper = styled.div`
  width: 15px;
  height: 15px;
  margin-right: 12px;
  position: relative;
  top: -16px;
  
  input {
    width: 17px;
    position: absolute;
    padding: 0;
    height: 18px;
    top: 15px;
  }
`
export const StyledCheckedCheckbox = styled.span`
    background-color: ${({ theme }) => theme.colors.telemediGreen};
    width: 17px;
    height: 18px;
    position: absolute;
    top: 15px;
    z-index: 1;
    pointer-events: none;

    &:after {
      content: "";
      position: absolute;
      left: 5px;
      top: 3px;
      width: 5px;
      height: 7px;
      border: solid ${({ theme }) => theme.colors.telemediWhite};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
`
