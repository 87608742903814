import styled, {css} from "styled-components"

export const StyledLanguageBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
  
  img {
    &:first-child {
      margin-right: 6px;
    }
  }
`

export const StyledArrow = styled.img`
  transform: rotate(90deg);
  margin: 0 10px 0 25px;
`

export const StyledLanguageLabel = styled.div`
  ${({ theme }) => theme.typography.h3};
`

export const StyledSelectLanguageBox = styled.div<{visible: boolean}>`
  position: absolute;
  top: 50px;
  background: ${({ theme }) => theme.colors.telemediWhite};
  border-radius: 8px;
  box-shadow: 0 1px 5px ${({ theme }) => theme.colors.telemediGray};
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  overflow-y: auto;
  max-height: 500px;
  
  ${({ visible }) => visible && css`
    visibility: visible;
    opacity: 1;
    transition: opacity .3s ease-in;
  `}
`
export const StyledLanguageOption = styled.div`
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  padding: 4px 16px 4px;
  cursor: pointer;

  &:first-child {
    padding-bottom: 0;
  }

  &:hover {
    background: rgba(32, 168, 105, 0.1);
    transition: all .5s;
  }

  > img {
    padding-right: 18px;
  }

  ${({ theme }) => theme.typography.h3};
`
