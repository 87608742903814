import i18next  from "i18next"
import {Languages} from "../utils/Language.utils"

const getTranslatedText = (key: string): string => i18next.t(`defaultTranslations:registerForm:${key}`)

export const getRegisterFormLabels = () => ({
  newsletterClause: getTranslatedText("newsletterClause"),
  rulesAccept: getTranslatedText("rulesAccept"),
  rulesLink: getTranslatedText("rulesLink"),
  andLabel: getTranslatedText("andLabel"),
  privacyPolicy: getTranslatedText("privacyPolicyLink"),
})

export const getRegisterFormLinks = () => {
  const lang = i18next.language
  return ({
    rules: `https://telemedi.com/${lang}/terms-and-conditions/`,
    privacyPolicy: `https://telemedi.com/${lang}/privacy-policy/`,
    login: {
      label: "defaultTranslations:registerForm:login"
    },
  })
}

export const errorTranslatePrefix: string = "apiError"
export const voucherErrorName: string = "voucher"
export const noDocumentAllowedAgeRange: number = 14
export const noDocumentAllowedCountry = Languages.ES
export const telemediEmail = "help@telemedi.com"
export const defaultTimezone = "Europe/Warsaw"

export const birthDateRegExp = new RegExp(/^([0-9]{4})[-]([0]?[1-9]|[1][0-2])[-]([0]?[1-9]|[1|2][0-9]|[3][0|1])$/)
export const differentFormatBirthDateRegExp = new RegExp(/^([0]?[1-9]|[1|2][0-9]|3[0|1])\-([0]?[1-9]|1[0-2])\-([0-9]{4})$/);

export const signalIdunaInsuranceNumber = new RegExp(/^([0-9]{6})[/]([0-9]{5})$/)

export const signalIdunaInsuranceCardTypes = {
  SIP: "SIP",
  SIZ: "SIZ"
}

export const allAvailableLanguages = ["pl", "en", "es", "pt", "ru", "ua", "lt", "rs", "cs", "de", "fr", "it", "hr", "bg", "ba"]
