import React, {FC, useEffect, useState, useMemo} from "react"
import { useTranslation } from "react-i18next"
import {useSelector} from "react-redux"
import Tracker from "@openreplay/tracker"
import trackerAssist from "@openreplay/tracker-assist"
import trackerAxios from "@openreplay/tracker-axios"
import {openReplayProjectKey} from "./app.config"
import api from "./api/api"
import { DefaultTheme } from "styled-components"
import Register from "./components/register/Register.component"
import Loader from "./components/common/Loader.component"
import {getClinicId, handleSetClinicSettings} from "./store/clinicSettings/clinicSettings.utils"
import { selectUserSettings } from "./store/userSettings/userSettings.selectors"
import {ThemeProvider} from "styled-components"
import {theme} from "./theme/theme"
import {FontStyles} from "./styles/FontStyles"
import {GlobalStyles} from "./theme/GlobalStyles"
import useMakeQuery from "./api/graphql/hooks/useMakeQuery"
import {setClinicStyles} from "./store/clinicSettings/clinicSettings.utils"
import ThankYouPage from "./components/thankYouPage/ThankYouPage.component"
import RandomSession from "./components/register/RandomSession.component"
import {generateRandomSessionId} from "./utils/Session.utils"

/* eslint-disable no-useless-escape */

const OPEN_REPLAY_INGEST_POINT = "https://openreplay.telemedi.com/ingest"
const OPEN_REPLAY_ANONYMOUS_KEY = "ANONYMOUS-"
const REGISTER_USER_API_URL = "api/v2/patients"
const RANDOM_SESSION_ID = generateRandomSessionId()

const tracker = new Tracker({
  projectKey: openReplayProjectKey,
  captureIFrames: false,
  ingestPoint: OPEN_REPLAY_INGEST_POINT,
}) //openReplay plugin

const App: FC = () => {
  const [appTheme, setAppTheme] = useState<DefaultTheme>(theme)
  const [sessionHash, setSessionHash] = useState("")
  const {t} = useTranslation()
  const userSettings = useSelector(selectUserSettings)
  const {getClinicSettingsQuery} = useMakeQuery()
  const clinicId = useMemo(() => {
    return getClinicId()
  }, [])
  const clinicSettingsData = clinicId ? getClinicSettingsQuery(`/api/clinics/${clinicId}`) : null

  useEffect(() => {
    if (clinicSettingsData?.data) {
      handleSetClinicSettings(clinicSettingsData?.data)
      setAppTheme(setClinicStyles(clinicSettingsData?.data?.frontendSettings?.style))

      tracker.use(trackerAssist({
        controlConfirm: {
          text: t("defaultTranslations:remoteControlLabel"),
          confirmBtn: t("defaultTranslations:accept"),
          declineBtn: t("defaultTranslations:reject")
        },
        callConfirm: {
          text: t("defaultTranslations:remoteCallLabel"),
          confirmBtn: t("defaultTranslations:answer"),
          declineBtn: t("defaultTranslations:reject"),
        }
      })) // openReplay tracker

      tracker.use(trackerAxios({
        instance: api,
        sanitiser: data => {
          const body = data?.request?.body ? JSON.parse(data.request.body) : null
          const hidePasswordString = "*****"

          if (data?.url.includes(REGISTER_USER_API_URL) && data.method === "POST") {
            body.plainPassword = hidePasswordString
            body.plainPasswordRepeat = hidePasswordString
            data.request.body = JSON.stringify(body)
          }

          return data
        },
      }))
      tracker.start({
        userID: `${OPEN_REPLAY_ANONYMOUS_KEY}${RANDOM_SESSION_ID}`,
        metadata: {
          clinicName: clinicSettingsData?.data?.name,
          clinicUrl: window.location.origin,
          clinicId: clinicSettingsData?.data?.frontendSettings?.clinicId
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicSettingsData?.data])

  useEffect(() => {
    if (userSettings.userIsRegistered) {
      setSessionHash(tracker.stop() || "")
    }
  }, [userSettings.userIsRegistered])

  if (clinicSettingsData?.dataLoading && getClinicId()) {
    return <Loader />
  }

  return (
    <ThemeProvider theme={appTheme}>
      <FontStyles />
      <GlobalStyles />
      {
        userSettings.userIsRegistered
          ? <ThankYouPage sessionHash={sessionHash || ""}/>
          : <Register />
      }
      <RandomSession sessionId={RANDOM_SESSION_ID} />
    </ThemeProvider>
  )
}

export default App
