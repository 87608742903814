import React from "react"
import loader from "../../assets/icons/loader.svg"
import {StyledLoaderContainer} from "./Loader.styles"

const Loader = () => {
  return (
      <StyledLoaderContainer>
        <img src={loader} alt=""/>
      </StyledLoaderContainer>
  )
}

export default Loader
