import React, { FC } from "react"
import { useTranslation } from "react-i18next"

import { StyledRandomSessionBox } from "./Register.styles"

type RandomSessionProps = {
    sessionId: string;
}

const Register: FC<RandomSessionProps> = ({sessionId}) => {
    const { t } = useTranslation()

    return (
        <StyledRandomSessionBox>
          {t("defaultTranslations:session")}: {sessionId}
        </StyledRandomSessionBox>
    )
}

export default Register
