import styled from "styled-components"

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 190px;
    padding-bottom: 40px;
    position: absolute;
    bottom: 0;
    background-color: ${({ theme }) => theme.colors.telemediGreen};
    @media ${({ theme }) => theme.breakpoints.small} {
        flex-direction: row;
        height: 109px;
        text-align: initial;
        justify-content: space-between;
        padding-bottom: 0;
    }
`

export const StyledLogo = styled.img`
    margin: 35px 0 0;
    height: 32px;
    
    @media ${({ theme }) => theme.breakpoints.small} {
        margin: 35px 0 0 32px;
    }
`

export const StyledContantSection = styled.div`
    display: flex;
    flex-direction: column;
    margin: 35px 0 0;
    color: ${({ theme }) => theme.colors.telemediWhite};
    ${({ theme }) => theme.typography.h3};
    line-height: 25px;
    
    @media ${({ theme }) => theme.breakpoints.small} {
        margin: 35px 90px 0 0;
    }
`

export const StyledLink = styled.a<{color?: string}>`
  color: ${({ theme }) => theme.colors.telemediWhite};
  text-decoration: underline;
  font-size: 16px;
`
