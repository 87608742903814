import React from "react"
import {useSelector} from "react-redux"
import {selectClinicSettings} from "../../store/clinicSettings/clinicSettings.selectors"
import {StyledClinicLogo, StyledClinicLogoContainer} from "../register/Register.styles"

const ClinicLogo = () => {
    const clinicSettings = useSelector(selectClinicSettings)
    const {frontendSettings: {images}} = clinicSettings

    return (
      images?.logo ? (
        <StyledClinicLogoContainer>
            <StyledClinicLogo src={images?.logo} alt={clinicSettings?.frontendSettings?.dashboardUrl || ""} />
        </StyledClinicLogoContainer>) : null
    )
}

export default ClinicLogo
