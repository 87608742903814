import React, { FC } from "react"
import {
  StyledButton,
} from "./Button.styles"

type ButtonType = "button" | "submit" | "reset" | undefined

interface ButtonProps {
  variant: string;
  label: string;
  type: ButtonType;
  loading: boolean;
  handleClick?(): void;
  fullWidth: boolean;
  buttonIcon?: JSX.Element | unknown;
}

const Button: FC<ButtonProps> = (
  {
     variant,
     label,
     type,
     handleClick,
     loading,
     fullWidth,
     buttonIcon
  }) => {
  return (
    <StyledButton
      onClick={handleClick}
      variant={variant}
      type={type}
      isLoading={loading}
      fullWidth={fullWidth}
    >
      { buttonIcon }
      { label }
    </StyledButton>
  )
}

export default Button
