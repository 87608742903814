import styled from "styled-components"

export const StyledLoaderContainer = styled.div`
  width: 64px;
  position: absolute;
  right: 50%;
  top: 40%;
  z-index: 2;

  > img {
    max-width: 100%;
  }
`
