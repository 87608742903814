import styled from "styled-components"

export const StyledRegisterContainer = styled.div`
  display: flex;
  min-height: 100vh;
  max-width: 550px;
  margin: auto;
  padding: 40px 0;
`

export const StyledRegisterContent = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
`

export const StyledRegisterHeader = styled.h1`
  ${({ theme }) => theme.typography.h1};
  margin-left: 12px;
`
export const StyledTopHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;  
`

export const StyledClinicLogo = styled.img`
  max-width: 100%;
  margin-bottom: 40px;
`

export const StyledClinicLogoContainer = styled.div`
  text-align: center;
`

export const StyledRandomSessionBox = styled.div`
  left: 0;
  color: ${({ theme }) => theme.colors.telemediWhite};
  min-width: 102px;
  bottom: 0;
  height: 25px;
  padding: 4px;
  z-index: 1100;
  position: absolute;
  background: ${({ theme }) => theme.colors.telemediBlack};
  font-weight: bold;
`
