
export const replaceBadTimezones = (timezone) => {
  const timeZones = {
    "America/Buenos_Aires": "America/Argentina/Buenos_Aires",
    "America/Catamarca": "America/Argentina/Catamarca",
    "America/ComodRivadavia" : "America/Argentina/ComodRivadavia",
    "America/Cordoba" : "America/Argentina/Cordoba",
    "America/Jujuy": "America/Argentina/Jujuy",
    "America/La_Rioja": "America/Argentina/La_Rioja",
    "America/Mendoza": "America/Argentina/Mendoza",
    "America/Rio_Gallegos": "America/Argentina/Rio_Gallegos",
    "America/Salta": "America/Argentina/Salta",
    "America/San_Juan": "America/Argentina/San_Juan",
    "America/San_Luis": "America/Argentina/San_Luis",
    "America/Tucuman": "America/Argentina/Tucuman",
    "America/Ushuaia": "America/Argentina/Ushuaia",
    "America/Indianapolis": "America/Indiana/Indianapolis",
    "America/Knox": "America/Indiana/Knox",
    "America/Marengo": "America/Indiana/Marengo",
    "America/Petersburg": "America/Indiana/Petersburg",
    "America/Tell_City": "America/Indiana/Tell_City",
    "America/Vevay": "America/Indiana/Vevay",
    "America/Vincennes": "America/Indiana/Vincennes",
    "America/Winamac": "America/Indiana/Winamac",
    "America/Beulah": "America/North_Dakota/Beulah",
    "America/Center": "America/North_Dakota/Center",
    "America/New_Salem": "America/North_Dakota/New_Salem",
  };

  if(!timeZones[timezone]){
    return timezone;
  }

  return timeZones[timezone];
};
