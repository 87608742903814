import React, {FC} from "react"
import i18next  from "i18next"
import {getDashboardUrl} from "./RegisterForm.utils"
import {getRegisterFormLinks} from "../../constants/constants"
import {useTranslation} from "react-i18next"
import {StyledLinkRegisterBox, StyledLoginSection} from "./RegisterForm.styles"

interface LoginSectionProps {
  isFirstRegisterStep: boolean;
}

const LoginSection: FC<LoginSectionProps> = ({isFirstRegisterStep}) => {
  const { t } = useTranslation()
  const registerFormLinks = getRegisterFormLinks()

  if (!isFirstRegisterStep) {
    return null
  }

  return (
    <StyledLoginSection>
      { t("defaultTranslations:registerForm:alreadyHaveAnAccount") }
      <StyledLinkRegisterBox href={`${getDashboardUrl()}/${i18next.language}/login`}>
        { t(registerFormLinks.login.label) }
      </StyledLinkRegisterBox>
    </StyledLoginSection>
  )
}

export default LoginSection
