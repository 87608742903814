import React, {FC, RefObject} from "react"
import {useTranslation} from "react-i18next"
import {StyledInfoBox} from "./RegisterForm.styles"

interface ErrorListProps {
  errorList?: string[];
  isFirstRegisterStep?: boolean;
  errorRef?: RefObject<HTMLInputElement | HTMLDivElement>;
}

const ErrorList: FC<ErrorListProps> = ({errorList, isFirstRegisterStep, errorRef}) => {
  const { t } = useTranslation()

  if (!errorList) {
    return null
  }

  return (
    <>
      {
        errorList.map((item, index) => {
          return (
            <StyledInfoBox key={item} isFirstRegisterStep={isFirstRegisterStep} ref={ index === 0 ? errorRef : null}>
              <p>{t(`validation:${item}`)}</p>
            </StyledInfoBox>
          )
        })
      }
    </>
  )
}

export default ErrorList
