import i18n, { Resource } from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import {allAvailableLanguages} from "../constants/constants"

import pl from "./pl"
import en from "./en"
import es from "./es"
import pt from "./pt"
import ru from "./ru"
import ua from "./ua"
import lt from "./lt"
import rs from "./rs"
import cs from "./cs"
import fr from "./fr"
import it from "./it"
import hr from "./hr"
import ba from "./ba"
import bg from "./bg"
import de from "./de"

// i18n resources
const resources: Resource = {
  pl,
  en,
  es,
  pt,
  ru,
  ua,
  lt,
  rs,
  cs,
  de,
  fr,
  it,
  hr,
  bg,
  ba
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    defaultNS: "defaultTranslations",
    fallbackLng: "en",
    whitelist: allAvailableLanguages,
    keySeparator: false, // we do not use keys in form messages.welcome
    detection: {
      order: ["path", "navigator"],
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    }
  })
  .then(() => {
    const detectedLang = i18n.language

    document.title = i18n.t("appTitle")
    document.documentElement.setAttribute("lang", detectedLang)
  })

export default i18n
