import React, {FC} from "react"
import { useTranslation } from "react-i18next"
import {telemediEmail} from "../../../constants/constants"
import logo from "../../../assets/icons/logo.svg"
import {StyledContainer, StyledLogo, StyledContantSection, StyledLink} from "./Footer.styles"

const Footer: FC = () => {
    const {t} = useTranslation()

    return (
        <StyledContainer>
            <StyledLogo src={logo} alt=""/>
            <StyledContantSection>
                <div>{t("defaultTranslations:registerSuccess:writeToUs")}</div>
                <StyledLink href="mailto:help@telemedi.com">{telemediEmail}</StyledLink>
            </StyledContantSection>
        </StyledContainer>
    )
}

export default Footer
