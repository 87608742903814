import React, {FC} from "react"
import Header from "./header/Header.component"
import InfoBox from "./infoBox/InfoBox.component"
import Footer from "./footer/Footer.component"
import {StyledContainer} from "./ThankYouPage.styles"

type ThankYouPageProps = {
  sessionHash: string;
}

const ThankYouPage: FC<ThankYouPageProps> = ({sessionHash}) => {
    return (
        <StyledContainer>
            <Header/>
            <InfoBox sessionHash={sessionHash}/>
            <Footer/>
        </StyledContainer>
    )
}

export default ThankYouPage
