import styled from "styled-components"

export const StyledHeader = styled.div`
    padding: 16px 0 16px 32px;
    height: 88px;
    background: ${({ theme }) => theme.colors.telemediWhite};
`

export const StyledImage = styled.img`
    max-height: 58px;
    display: flex;
    align-items: center;
`
