import {gql} from "@apollo/client"

export const GET_CLINIC_SETTINGS = gql`
  query getClinicSettings($clinicId: ID!) {
    clinic(id: $clinicId) {
      id
      domain
      name
      couponInRegister
      requireCoupon
      collectMedicalDocument
      frontendSettings {
        style
        images
        seo
        dashboardUrl
        clinicId
        modules
      }
      enabledLanguages {
        languages
      }
      widgetRegisterSettings {
        linkToRegulations
        linkToPrivacyPolicy
        hidePeselOption
      }
      clinicSignalIdunaSettings {
        insuranceVerificationEnabled
      }
    }
  }
`
