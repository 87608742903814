import React, {FC, ReactNode} from "react"
import InputMask from "react-input-mask"
import warning from "../../../../assets/icons/warning.svg"
import { useForm, Controller } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message/dist"
import { RegisterFormTypes } from "../../RegisterForm.types"
import { StyledInput, StyledErrorMessage, StyledWarningIcon } from "./Input.styles"

interface InputProps extends RegisterFormTypes {
  label?: ReactNode;
  disabled?: boolean;
  defaultValue?: string;
  onChange?(event: React.ChangeEvent<HTMLInputElement>): unknown;
  required?: boolean;
  mask?: string;
}

const Input: FC<InputProps> = (
  {
    type,
    placeholder,
    name,
    register,
    validation = {},
    errors,
    disabled,
    defaultValue,
    onChange,
    required = true,
    mask,
  }
) => {
  const {
    getValues,
    control,
  } = useForm()
  const hasError = errors[name]
  const phoneInput = (name === "prefixCode" || name === "phoneNumber")
  const warningIconHiddenInElements: string[] = ["checkbox", "password", "plainPassword", "plainPasswordRepeat"]
  const form = register(name, validation)
  const inputElement = (
    <StyledInput
       hasError={hasError}
       type={type || "text"}
       {...form}
       autoComplete="new-password"
       placeholder={placeholder}
       defaultValue={defaultValue || getValues(name)}
       id={name}
       name={name}
       disabled={disabled}
       onChange={mask ? undefined : (onChange ?? form.onChange)}
       data-required={required}
    />
  )

  const passwordValidationErrors = () => {
    const passwordErrors = []
    const plainPasswordField = "plainPassword"

    if (name === plainPasswordField && errors?.plainPassword?.types?.matches?.length) {
      const passwordErrorsData = errors?.plainPassword?.types?.matches
      const message = errors.plainPassword?.message

      if (Array.isArray(passwordErrorsData)) {
        passwordErrorsData.map((item) => (
          (item !== message) && passwordErrors.push(<StyledErrorMessage key={item}>{ item }</StyledErrorMessage>)
        ))
      } else if (message !== passwordErrorsData) {
        passwordErrors.push(<StyledErrorMessage key={passwordErrorsData}>{ passwordErrorsData }</StyledErrorMessage>)
      }
    }

    return (
      <>
        { passwordErrors }
      </>
    )
  }

  return (
    <>
      {
        (hasError && !phoneInput) && (
          <StyledWarningIcon hidden={(warningIconHiddenInElements.includes(name) || warningIconHiddenInElements.includes(type || ""))}>
            <img src={warning} alt="" />
          </StyledWarningIcon>
        )
      }
        {
          mask
            ? (
             <Controller
                control={control}
                name="birthDate"
                render={() => (
                   <InputMask
                      mask={mask}
                      {...register(name, validation)}
                   >
                     {() => inputElement}
                   </InputMask>
                   )}
                />
             ) : inputElement
        }
      <StyledErrorMessage>
        { ((errors && !errors.termsAccepted) || type !== "checkbox") &&
          (
            !phoneInput
              ? <ErrorMessage errors={errors} name={name} />
              : null
          )
        }
      </StyledErrorMessage>
      { passwordValidationErrors() }
    </>
  )
}

export default Input
