import React, { FC, useState, useEffect, useRef } from "react"
import {useSelector} from "react-redux"
import i18next  from "i18next"
import SelectLanguage from "../selectLanguage/SelectLanguage.component"
import {selectClinicSettings} from "../../store/clinicSettings/clinicSettings.selectors"
import { getFlagSrc } from "../../utils/Language.utils"
import arrow from "../../assets/icons/arrow.svg"

import {
  StyledLanguageBox,
  StyledLanguageLabel,
  StyledArrow,
} from "./LanguageInfo.styles"
import { allAvailableLanguages } from "../../constants/constants"

interface LanguageInfoProps {}

const LanguageInfo: FC<LanguageInfoProps> = () => {
  const currentLanguage = i18next.language
  const [showSelectLanguage, setShowSelectLanguage] = useState<boolean>(false)
  const clinicSettings = useSelector(selectClinicSettings)
  const clinicEnabledLanguages = clinicSettings.enabledLanguages
  const languages =  (clinicSettings.id && clinicEnabledLanguages?.languages?.length) ? clinicEnabledLanguages.languages : allAvailableLanguages
  const ref = useRef<HTMLDivElement>(null)

  const toggleLanguageSelection = () => {
    setShowSelectLanguage(!showSelectLanguage)
  }

  useEffect(() => {
    const handleClickOutside = (event: React.ChangeEvent<HTMLInputElement>|MouseEvent) => {

      if (!ref?.current?.contains(event.target as Element)) {
        setShowSelectLanguage(false)
      }
    }

    if (ref.current) {
      document.addEventListener("click", handleClickOutside, true)
    }

    return () => document.removeEventListener("click", handleClickOutside, true)
  }, [ref])

  if (languages.length === 1) {
    return null
  }

  return (
    <StyledLanguageBox ref={ref} onClick={toggleLanguageSelection}>
      <img src={getFlagSrc(currentLanguage)} alt="" />
      <StyledLanguageLabel>
        { currentLanguage.toUpperCase() }
      </StyledLanguageLabel>
      <StyledArrow src={arrow} alt="" />
      <SelectLanguage visible={showSelectLanguage} languages={languages} />
    </StyledLanguageBox>
  )
}

export default LanguageInfo
