import React from "react"
import ReactDOM from "react-dom"
import {Provider} from "react-redux"
import "./i18n/i18n"
import App from "./App"
import { ApolloProvider } from "@apollo/client"
import { client } from "./api/graphql/apolloClient"
import store from "./store"


ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <App/>
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
)
