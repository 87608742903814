import React, {FC} from "react"
import { useTranslation } from "react-i18next"
import {
  StyledVoucherSection,
  StyledVoucherCodeLabel,
} from "./VoucherSection.styles"

interface VoucherSectionProps {
  children: JSX.Element;
}

const VoucherSection: FC<VoucherSectionProps> = ({children}) => {
  const { t } = useTranslation()

  return (
    <StyledVoucherSection>
      <StyledVoucherCodeLabel>
        {t("defaultTranslations:registerForm:voucherCode")}
      </StyledVoucherCodeLabel>
      {children}
    </StyledVoucherSection>
  )
}

export default VoucherSection
