export const colors: Record<string, string> = {
  telemediGreen: "#20A869", // tele-green
  telemediGreenDark: "#0E7E4A", // tele-green-40
  telemediGreenDarker: "#085431", // tele-green-60
  telemediWhite: "#FFFFFF",
  telemediGray: "#2D2D2D",
  telemediGray60: "#787878",
  telemediBlueDark: "#164386", // tele-blue-40
  telemediRed: "#B93C3C",
  telemediPageBackground: "#F6F5F2", // tele-beige
  telemediGray20: "#C4C4C4",
  telemediGray10: "#EBEBEB",
  telemediBlack: "#3C3F3D", // tele-black
}
