import { useQuery } from "@apollo/client"
import {GET_CLINIC_SETTINGS} from "../queries/getClinicSettingsQuery"

const useMakeQuery = () => {
  const getClinicSettingsQuery = (clinicId: string) => {
    const {
      data: { clinic: data } = {},
      loading: dataLoading,
    } = useQuery(GET_CLINIC_SETTINGS, {
      variables: {clinicId}
    })

    return {
      data,
      dataLoading,
    }
  }

  return {
    getClinicSettingsQuery,
  }
}

export default useMakeQuery
